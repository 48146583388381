import { pick } from 'lodash-es';
import { Question } from '../models/question.model';
import { RawQuestion } from './common.interface';

export function toQuestion(raw: RawQuestion): Question {
  return {
    approved: !!raw.approved,
    id: raw.id,
    title: raw.title,
    description: raw.description,
    image: raw.image,
    ltr: +raw.ltr === 1,
    answers: raw.answers
      .map((answer) => ({
        ...pick(answer, ['id', 'title', 'description', 'order', 'image']),
        // todo use returned value
        questionId: raw.id,
        approved: !!answer.approved,
        correct: !!answer.correct,
      }))
      .sort((a, b) => a.order - b.order),
  };
}
